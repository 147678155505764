<template>
  <div
    ref="labelContainer"
    :class="['px-2 w-full', { 'flex items-center h-full': !showFull }]"
  >
    <div
      v-if="labels.length"
      :class="[
        'flex items-center flex-1 w-full overflow-hidden gap-1',
        showFull ? 'flex-wrap' : 'flex-nowrap',
      ]"
    >
      <div
        v-for="label in visibleLabels"
        :key="label.id"
        class="px-2 min-h-6 rounded-md py-1"
        :style="getLabelStyle(label.color as T)"
      >
        <Tooltip :text="label.name" class="flex items-center">
          <template #default="{ getTextRef }">
            <span
              :ref="getTextRef"
              :class="[
                'line-clamp-1 text-xs leading-4 max-h-4 flex items-start',
                showFull ? 'whitespace-nowrap' : 'break-all',
              ]"
            >
              {{ label.name }}
            </span>
          </template>
        </Tooltip>
      </div>
      <UTooltip
        v-if="hiddenLabels.length > 0"
        :popper="{ placement: 'top', arrow: true }"
        :prevent="hiddenLabels.length === 0"
        :ui="{ base: 'h-fit', background: 'bg-white', container: 'max-w-96' }"
      >
        <template #text>
          <div>
            <div
              v-for="label in hiddenLabels"
              :key="label.id"
              class="text-xs leading-4 truncate"
            >
              {{ label?.name }}
            </div>
          </div>
        </template>
        <div
          ref="hiddenLabelsCounter"
          class="text-xs text-gray-700 px-1.5 py-0.5 bg-gray-200 rounded-md"
        >
          +{{ hiddenLabelsCount }}
        </div>
      </UTooltip>
    </div>
    <div
      v-else
      class="py-1 rounded-md text-xs group-hover/cell:visible invisible"
      data-test="checkbox-cell-empty"
    >
      -
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends string">
import type { PropType, StyleValue } from 'vue'
import type { FieldLabel } from '#field/types'

const props = defineProps({
  labels: {
    type: Object as PropType<Array<FieldLabel>>,
    required: true,
  },
  getLabelStyle: {
    type: Function as PropType<(color: T) => StyleValue>,
    required: true,
  },
  showFull: {
    type: Boolean,
    default: false,
  },
  width: {
    type: Number as PropType<number | null>,
    default: null,
  },
})

const labelContainer = ref<HTMLDivElement | null>(null)
const hiddenLabelsCounter = ref<HTMLDivElement | null>(null)
const visibleLabels = ref<FieldLabel[]>([])
const hiddenLabelsCount = ref(0)

const hiddenLabels = computed(() => {
  return labels.value.slice(visibleLabels.value.length)
})

const labels = computed(() => props.labels || [])

const estimateLabelWidth = (text: string) => {
  const avgCharWidth = 8
  const padding = 16
  return text.length * avgCharWidth + padding
}

const updateVisibleLabels = async () => {
  let currentWidth = 0
  const containerWidth = props.width ?? labelContainer.value?.offsetWidth ?? 0
  const hiddenCounterWidth = hiddenLabelsCounter.value?.offsetWidth || 0 + 16
  const newVisibleLabels = labels.value.reduce((acc, label, currentIndex) => {
    const labelWidth = estimateLabelWidth(label.name || '')

    if (currentIndex === 0) {
      currentWidth += labelWidth
      return [label]
    }

    if (currentWidth + labelWidth + hiddenCounterWidth <= containerWidth) {
      currentWidth += labelWidth + 4 // 4px for gap
      return [...acc, label]
    }

    return acc
  }, [] as FieldLabel[])

  visibleLabels.value = newVisibleLabels
  hiddenLabelsCount.value = labels.value.length - newVisibleLabels.length
}

let resizeObserver: ResizeObserver | null = null

onMounted(() => {
  nextTick(() => {
    if (props.showFull) {
      visibleLabels.value = props.labels
      return
    }

    updateVisibleLabels()
    /**
     * If width is not provided, we need to observe the container for resize to update the visible labels
     * Hint: Using width prop is more performant
     */
    if (props.width === null) {
      resizeObserver = new ResizeObserver(() => {
        updateVisibleLabels()
      })

      if (labelContainer.value) {
        resizeObserver.observe(labelContainer.value)
      }
    }
  })
})

watch(() => props.width, updateVisibleLabels)

watch(
  () => props.labels,
  (value) => {
    if (props.showFull) {
      visibleLabels.value = value
      return
    }

    if (value.length) {
      nextTick(updateVisibleLabels)
    }
  },
  { deep: true }
)

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})
</script>
